$(document).ready(function() {
    // START Phone Mask //
    $("input[name=phone]").mask("+7 (999) 999-99-99", { placeholder: "+7 (___) ___-__-__" });
    // END Phone Mask //

    // START Compilations Block //
    $(".compilations__block .links__item").click(function(){
        if ($(this).attr('data-compilation')) {
            $('.compilations__block .links__item').removeClass('current');
            $(this).addClass('current');
            var data = {};
            data['action'] = 'compilations';
            data['type'] = $(this).attr('data-compilation');
            $.ajax({
                type: "post",
                url: "/ajax",
                data: data,
                success: function(response){
                    $(".compilations__block .compilations .swiper-wrapper").html(response);
                }
            });
        }
    });
    // END Compilations Block //

    // START Filter Block //
    $(".filter__block .props__item .title").click(function(){
        $(this).toggleClass('closed')
    });
    $(".filter__block .buttons .reset").click(function(){
        $(".filter__block .props_values__item").removeClass('checked');
        $('.filter__block .props_values__item___checkbox .checkbox').removeAttr('checked');
        $(".filter__block .filter__form").reset();
    });
    // END Filter Block //

    // START Sort Block //
    //// START Sort Params ////
    $(".sort__block .sort_params .title").click(function(){
        $(this).parent().toggleClass('active');
    });
    // Скрытие выпадающего списка параметров сортировке при клике вне этого списка
    $('body').mouseup(function (e) {
        var container = $(".sort__block .sort_params .sort__items");
        if (container.has(e.target).length === 0 && container.parent().has(e.target).length === 0){
            container.parent().removeClass('active');
        }
    });
    $(".sort__block .sort__item").click(function(){
        $(this).parent().find(".sort__item").removeClass('selected');
        $(this).addClass('selected');
        var data = {};
        data['sort_type'] = $(this).parent().attr('id');
        data['sort'] = $(this).attr('data-sort');
        if ($(this).attr('data-dir')) {
            data['sort_dir'] = $(this).attr('data-dir');
        }

        $.ajax({
            type: "post",
            url: window.location,
                data: data,
                success: function(response) {
                    location.reload();
            }
        });
    });
    //// END Sort Params ////
    // END Sort Block //


    // START Product Item Gallery //
    $(".product__item .gallery").mouseleave(function() {
        const $elements = $(this).find(".wrapper .image");
        $elements.length && ($elements.removeClass("active"), $($elements[0]).addClass("active"))
    })
    $('body').on('mouseenter','.product__item .gallery .image',function(){
        $(this).siblings().removeClass("active"), $(this).addClass("active")
    })
    // END Product Item Gallery //


    // START Checkout Options //
    $(".checkout__item .options .options__item").click(function() {
        $(this).parent().find('.options__item').removeClass('active');
        $(this).addClass('active');
    })
    // END Checkout Options //

    // START Footer Menu Open / Close //
    $("footer .menu .title").click(function() {
        $("footer .menu .menu__items").not($(this).siblings('.menu__items')).removeClass('active');
        $(this).siblings('.menu__items').toggleClass('active');
    })
    // END Footer Menu Open / Close //


    // START Адаптивное оформление таблиц контентного блока //
    $('.content__block table').each(function() {
        var table = $(this);
        var thead_items;
        if (table.find('th').length > 0) {
            thead_items = table.find('th');
        } else {
            thead_items = table.find('thead td');
        }
        thead_items.each(function(index) {
            var counter = index;
            var th = $(this);
            table.find('tbody tr').each(function() {
                var td = $(this).find('td').eq(counter);
                if (th.height() > td.height()) {
                    var source = th;
                    var dest = td;
                } else {
                    var source = td;
                    var dest = th;
                }
                dest.outerHeight(source.outerHeight(true));
            });
            table.find('tbody tr').each(function() {
                var td = $(this).find('td').eq(counter);
                if (th.height() > td.height()) {
                    var source = th;
                    var dest = td;
                } else {
                    var source = td;
                    var dest = th;
                }
                dest.outerHeight(source.outerHeight(true));
            });
        });
    });
    // END Адаптивное оформление таблиц контентного блока //
});
