$(document).ready(function() {
    // Показывает/скрывает меню каталога при нажатии на кнопку
    $('header nav.main .catalog__button').click(function() {
        $(this).parents('header').find('nav.main').toggleClass('active');
        $(this).toggleClass('active');
    });

    //
    $('nav.main .dropdown_menu').mouseleave(function() {
        $(this).find('.submenu').removeClass('active');
        $(this).find('.submenu.current').removeClass('nonactive');
    });

    $('nav.main .dropdown_menu .submenu_toggle').mouseenter(function() {
        $(this).parents('.dropdown_menu').find('.submenu').removeClass('active');
        $(this).next('.submenu').addClass('active');
        $(this).parents('.dropdown_menu').find('.submenu.current').removeClass('nonactive');
        $(this).parents('.dropdown_menu').find('.submenu.current').addClass('nonactive');
        setTimeout(function(){
            $(this).removeClass('active');
        }, 5000);
    });

}); 