// START Close Popups //
$(".popup .close_popup").on("click", function(e) {
    e.preventDefault();
    $(this).parents(".popup").removeClass('active');
    $(this).parents(".popup").next(".backdrop").removeClass('active');
});

$(".popup + .backdrop").on("click", function(e) {
    $(this).prev(".popup").removeClass('active');
    $(this).removeClass('active');
});
// END Close Popups //

// START Open Popups //
$(".callback__button").on("click", function(e) {
    $(".popup").removeClass('active');
    $(".callback__popup").addClass('active');
});

$(".price_request__button").on("click", function(e) {
    $('#price_request__value').val($(this).attr('data-product_id'));
    $(".popup").removeClass('active');
    $(".price_request__popup").addClass('active');
});
// END Open Popups //