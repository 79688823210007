$(document).ready(function() {
    // START Banner Block // Слайдер баннера на главной
    var banner__swiper = new Swiper('.banner__block .banner .swiper', {
        slidesPerView: 1,
        navigation: {
            nextEl: ".banner__block .banner .swiper-nav .swiper-button-next",
            prevEl: ".banner__block .banner .swiper-nav .swiper-button-prev"
        }
    });
    // END Banner Block //

    // START Compilations Block // Слайдер подборок на главной
    var compilations__swiper = new Swiper('.compilations__block .swiper', {
        slidesPerView: 2,
        slidesPerColumn: 4,
        spaceBetween: 6,
        watchOverflow: false,
        navigation: {
            nextEl: ".compilations__block .swiper-nav .swiper-button-next",
            prevEl: ".compilations__block .swiper-nav .swiper-button-prev"
        },
        breakpoints: {
            668: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            992: {
                slidesPerView: 4,
            }
        }
    });
    // END Compilations Block //

    // START Looked Block // Слайдер подборок на главной
    var looked__swiper = new Swiper('.looked__block .swiper', {
        slidesPerView: 1,
        slidesPerColumn: 4,
        spaceBetween: 24,
        watchOverflow: false,
        navigation: {
            nextEl: ".looked__block .swiper-nav .swiper-button-next",
            prevEl: ".looked__block .swiper-nav .swiper-button-prev"
        },
        breakpoints: {
            668: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 4,
            }
        }
    });
    // END Looked Block //


    // Слайдер галереи
    var gallery_slider = new Swiper(".gallery__block___slider", {
        slidesPerView: 1,
        slidesPerColumn: 4,
        spaceBetween: 12,
        breakpoints: {
            668: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            992: {
                slidesPerView: 4,
            }
        },
        watchOverflow: true,
        autoHeight: true,
        rewind: true,
        shortSwipes: true,
        navigation: {
            nextEl: ".gallery__block___slider .nav .swiper-button-next",
            prevEl: ".gallery__block___slider .nav .swiper-button-prev",
        },
        on: {//Фикс несрабатывающих кликов по изображению
          touchEnd: function(s,e) {
            let range = 5;
            let diff = s.touches.diff = s.isHorizontal() ? s.touches.currentX - s.touches.startX : s.touches.currentY 
                         - s.touches.startY
            if (diff < range || diff > -range) s.allowClick = true;
          }
        },
        slidesPerView: 'auto',
    });
    Fancybox.bind(".gallery__block___slider .swiper-slide:not(.swiper-slide-duplicate)", {
        groupAll : true,
        selector: '.gallery__block___slider .swiper-slide:not(.swiper-slide-duplicate)',
        backFocus: false
    });

    // Слайдер галереи на странице товаров
    //// Активация первого изображения галереи
    $(".gallery__block___detail").mouseleave(function() {
        const $elements = $(this).find("gallery__block___detail .gallery__item___detail");
        $elements.length && ($elements.removeClass("active"), $($elements[0]).addClass("active"))
    })
    $(".gallery__block___detail .gallery__item___detail").mouseenter(function() {
        $(this).siblings().removeClass("active"), $(this).addClass("active")
    })

    //// Вывод галереи превьюшек
    var gallery_thumbs = new Swiper(".gallery__block___thumbs", {
        direction: "vertical",
        spaceBetween: 12,
        watchOverflow: true,
        autoHeight: true,
        rewind: true,
        shortSwipes: false,
        navigation: {
            nextEl: ".gallery__block___thumbs .nav .swiper-button-next",
            prevEl: ".gallery__block___thumbs .nav .swiper-button-prev",
        },
        on: {//Фикс несрабатывающих кликов по изображению
          touchEnd: function(s,e) {
            let range = 5;
            let diff = s.touches.diff = s.isHorizontal() ? s.touches.currentX - s.touches.startX : s.touches.currentY 
                         - s.touches.startY
            if (diff < range || diff > -range) s.allowClick = true;
          }
        },
        slidesPerView: 'auto',
    });
    var gallery_big = new Swiper(".gallery__block___detail", {
        loop: true,
        centeredSlides: true,
        navigation: {
            nextEl: ".gallery__block___detail .swiper-button-next",
            prevEl: ".gallery__block___detail .swiper-button-prev",
        },
        thumbs: {
            swiper: gallery_thumbs,
        },
        pagination: {
            el: ".gallery__block___detail .pagination",
            clickable: true,
        },
    });
    Fancybox.bind(".gallery__block___detail .swiper-slide:not(.swiper-slide-duplicate)", {
        groupAll : true,
        selector: '.gallery__block___detail .swiper-slide:not(.swiper-slide-duplicate)',
        backFocus: false
    });


/*
    // Общее оформление для слайдеров
    $('.slidewer .swiper').each(function() {
        var offsetBefore = 15;
        var offsetAfter = -15;
        var spaceBetween = 30;
        if ($(this).parents('.menu')) {
            offsetBefore = 0;
            offsetAfter = 0;
            spaceBetween = 15;
        }
        var swiper = new Swiper(this, {
            slidesPerColumn: 4,
            slidesOffsetBefore: offsetBefore,
            slidesOffsetAfter: offsetAfter,
            spaceBetween: spaceBetween,
            navigation: {
                nextEl: $(this).siblings('.slider-nav').find('.swiper-button-next')[0],
                prevEl:  $(this).siblings('.slider-nav').find('.swiper-button-prev')[0],
            },
            focusableElements: 'br',//For disable unwanted scrolling, when click on product elements
            shortSwipes: true,
            watchOverflow: false,
            slidesPerView: 1,
            breakpoints: {
                668: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 4,
                },
            }
        });
    });
    $('.image_slider .swiper').each(function() {
        var swiper = new Swiper(this, {
            slidesPerColumn: 4,
            spaceBetween: 30,
            navigation: {
                nextEl: $(this).find('.swiper-button-next')[0],
                prevEl:  $(this).find('.swiper-button-prev')[0],
            },
            focusableElements: 'br',//For disable unwanted scrolling, when click on product elements
            shortSwipes: true,
            watchOverflow: false,
            slidesPerView: 1,
            breakpoints: {
                668: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 4,
                },
            }
        });
    });
    Fancybox.bind(".image_slider .swiper-slide:not(.swiper-slide-duplicate)", {
        groupAll : true,
        selector: '.image_slider .swiper-slide:not(.swiper-slide-duplicate)',
        backFocus: false
    });
*/
});